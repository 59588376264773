import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  isLogin,
  isVerified,
  isSubscribed,
  getUserPhoneNumber,
} from "../utils";
import {
  checkUserSubscription,
  subscribeSuccess,
} from "../store/reducers/userSlice.js";

const PrivateRoute = () => {
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to login page

  console.log("user statuses: ", {
    isLogin: isLogin(),
    isVerified: isVerified(),
    isSubscribed: isSubscribed(),
    phoneNumber: getUserPhoneNumber(),
  });

  useEffect(() => {
    (isLogin() && isVerified() && !isSubscribed()) &&
      (() => {
        try {
          checkUserSubscription(getUserPhoneNumber(), response => {
            console.log("result of checkUserSubscription: ", response);
            subscribeSuccess(response);
          });
        } catch (err) {
          console.error("error in checkUserSubscription: ", err);
        }
      })();
  }, []);

  if (isLogin()) {
    // If the page is the phone verify page, do not redirect
    const location = useLocation();
    if (location.pathname === "/phone-verify") {
      console.log("entering phone verify page");
      if (isVerified() && isSubscribed()) {
        return <Navigate to={`/`} />;
      } else {
        return <Outlet />;
      }
    }

    if (!isVerified() || !isSubscribed()) {
      return <Navigate to={`/phone-verify`} />;
    }

    return <Outlet />;
  } else {
    return <Navigate to={`/login`} />;
  }
};

export default PrivateRoute;
